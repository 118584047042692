import { Translation } from './types';
export default {
  Header: {
    subtitle: 'Panaszkodjunk rendezetten',
    logout: 'Kijelentkezés',
    leave: 'Távozás',
    summaryMode: 'Összesített mód',
  },
  LanguagePicker: {
    header: 'Válassz nyelvet',
  },
  Main: {
    hint: 'Ha meg akarsz hívni másokat, másold ki és oszd meg velük az URL-t',
  },
  Home: {
    welcome: undefined,
  },
  PreviousGame: {
    createdBy: undefined,
    posts: undefined,
    participants: undefined,
    votes: undefined,
    actions: undefined,
  },
  Column: {
    createGroupTooltip: undefined,
  },
  Group: {
    emptyGroupTitle: '',
    emptyGroupContent: '',
  },
  Post: {
    openExtra: undefined,
    closeExtra: undefined,
    vote: 'szavazat',
    votes: 'szavazat',
    deleteButton: 'törlés',
    setActionButton: undefined,
    setGiphyButton: undefined,
    noContent: '(This post has no content)',
    by: undefined,
    upVote: undefined,
    downVote: undefined,
    voteRemainingMultiple: undefined,
    voteRemainingOne: undefined,
    voteRemainingNone: undefined,
    toggleGiphyButton: undefined,
  },
  Customize: {
    title: undefined,
    votingCategory: undefined,
    votingCategorySub: undefined,
    postCategory: undefined,
    postCategorySub: undefined,
    customTemplateCategory: undefined,
    customTemplateCategorySub: undefined,
    startButton: undefined,
editButton: undefined,
    maxUpVotes: undefined,
    maxUpVotesHelp: undefined,
    maxDownVotes: undefined,
    maxDownVotesHelp: undefined,
    allowSelfVoting: undefined,
    allowSelfVotingHelp: undefined,
    allowMultipleVotes: undefined,
    allowMultipleVotesHelp: undefined,
    allowActions: undefined,
    allowActionsHelp: undefined,
    allowAuthorVisible: undefined,
    allowAuthorVisibleHelp: undefined,
    allowGiphy: undefined,
    allowGiphyHelp: undefined,
    allowGrouping: undefined,
    allowGroupingHelp: undefined,
    allowReordering: undefined,
    allowReorderingHelp: undefined,
    blurCards: undefined,
    blurCardsHelp: undefined,
    template: undefined,
    templateHelp: undefined,
    numberOfColumns: undefined,
    numberOfColumnsHelp: undefined,
    makeDefaultTemplate: undefined,
  },
  PostBoard: {
    customQuestion: undefined,
    notWellQuestion: 'Mit lehetne jobban csinálni?',
    wellQuestion: 'Mit ment jól?',
    ideasQuestion: 'Van valami nagyszerű ötleted?',
    startQuestion: undefined,
    editButton: undefined,
    stopQuestion: undefined,
    continueQuestion: undefined,
    likedQuestion: undefined,
    lackedQuestion: undefined,
    learnedQuestion: undefined,
    longedForQuestion: undefined,
    anchorQuestion: undefined,
    boatQuestion: undefined,
    islandQuestion: undefined,
    windQuestion: undefined,
    rockQuestion: undefined,
    disconnected: undefined,
    reconnect: undefined,
    notLoggedIn: undefined,
  },
  GameMenu: {
    board: undefined,
    summary: undefined,
  },
  Template: {
    default: undefined,
    wellNotWell: undefined,
    startStopContinue: undefined,
    fourLs: undefined,
    sailboat: undefined,
  },
  Clients: {
    header: 'Jelenleg itt van:',
  },
  Join: {
    welcome: 'Üdv, ez itt a Retrospected',
    standardTab: {
      header: 'Ülés létrehozása',
      text: 'Kattints ide a kezdéshez:',
      button: 'Új ülés indítása',
      customizeButton: undefined,
    },
    optionsTab: {
      header: 'Haladó',
      input: 'Adj nevet az ülésnek',
      button: 'Ülés létrehozása',
    },
    previousTab: {
      header: 'Previous sessions',
      rejoinButton: 'Rejoin',
    },
  },
  Login: {
    namePlaceholder: 'Hogy is hívnak? Kérlek írd ide a nevedet',
    buttonLabel: 'Kezdjük',
    header: 'Login',
    socialMediaAuthHeader: undefined,
    socialMediaAuthDescription: undefined,
    anonymousAuthHeader: undefined,
    anonymousAuthDescription: undefined,
    authenticatingWith: undefined,
    or: undefined,
  },
  SummaryBoard: {
    noPosts: 'There are no posts to display',
    copyAsMarkdown: undefined,
    copyAsRichText: undefined,
    copySuccessful: undefined,
  },
  SessionName: {
    defaultSessionName: 'My Retrospective',
  },
  Invite: {
    inviteButton: 'Invite',
    dialog: {
      title: 'Invite people to your retrospective',
      text:
        'To invite people to your retrospected session, simply send them ' +
        'the following URL',
      copyButton: 'Copy URL to Clipboard',
    },
  },
  Generic: {
    ok: 'OK',
    cancel: 'Megszünteti',
  },
  Actions: {
    tooltip: 'Hozzon létre egy műveletet az elem hátoldalán',
    label: 'Nyissa meg a Művelet panelt',
    summaryTitle: 'Az Ön tevékenységei',
    title: 'Akció',
  },
  DeleteSession: {
    header: undefined,
    firstLine: undefined,
    secondLine: undefined,
    yesImSure: undefined,
    cancel: undefined,
  },
  RevealCards: {
    buttonLabel: undefined,
    dialogTitle: undefined,
    dialogContent: undefined,
    confirmButton: undefined,
    cancelButton: undefined,
  },
} as Translation;
